import React from 'react';

// screens
import { AuthLayout } from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import { MainColoredLayout } from 'layouts/MainLayout/MainColoredLayout';
import { MainMessageLayout } from 'layouts/MainLayout/MainMessageLayout';
import Plain from 'layouts/Plain';
import SimpleLayout from 'layouts/SimpleLayout';
import SSOLoginLayout from 'layouts/SSOLoginLayout';
import { AccessAccount } from 'screens/AccessAccount/AccessAccount';
import { ConfirmationMembershipScreen } from 'screens/ActivateMembership/ConfirmMembership/ConfirmationMembershipScreen';
import { ActivateMembershipScreen } from 'screens/ActivateMembership/EnterAccessCode/ActivateMembershipScreen';
import UniqueIdScreen from 'screens/ActivateMembership/EnterUniqueId/UniqueIdScreen';
import { VerifyEligibilityScreen } from 'screens/ActivateMembership/VerifyEligibility/VerifyEligibilityScreen';
import { WalmartConfirmYourIdentityScreen } from 'screens/ActivateMembership/WalmartConfirmYourIdentityScreen';
import { WalmartWinOrBidScreen } from 'screens/ActivateMembership/WalmartWinOrBidScreen';
import { Appointments } from 'screens/Appointments/Appointments';
import { Appointments as AppointmentsV2 } from 'screens/AppointmentsV2/Appointments';
import AppointmentVirtualMeeting from 'screens/AppointmentVirtualMeeting';
import { Book } from 'screens/Book/Book';
import { BookingQuiz } from 'screens/BookingQuiz/BookingQuiz';
import { ConfirmNewEmail } from 'screens/ConfirmNewEmail/ConfirmNewEmail';
import { CreateAccountScreen } from 'screens/CreateAccount/CreateAccount';
import CyclePlanAcknowledge from 'screens/CyclePlanAcknowledge';
import { Dashboard } from 'screens/DashboardV2/Dashboard';
import DirectPayment from 'screens/DirectPayment/DirectPayment';
import { Documents } from 'screens/Documents/Documents';
import { EmailChange } from 'screens/EmailChange/EmailChange';
import { EmailVerificationScreen } from 'screens/EmailVerificationScreen/EmailVerificationScreen';
import { ForgotPassword } from 'screens/ForgotPassword/ForgotPassword';
import { InformedConsent } from 'screens/InformedConsent/InformedConsent';
import { IntakeForm } from 'screens/IntakeForm/IntakeForm';
import { InvoiceDetails } from 'screens/InvoiceDetails/InvoiceDetails';
import { Invoices } from 'screens/Invoices/Invoices';
import { Kindbody360Index } from 'screens/Kb360';
import { LoginScreen } from 'screens/Login/LoginScreen';
import { LoginSsoAzureMedtronic } from 'screens/LoginSsoAzureMedtronic';
import LoginSsoEpicDisney from 'screens/LoginSsoEpicDisney';
import { Logout } from 'screens/Logout/Logout';
import MedicationInstruction from 'screens/MedicationInstructions/MedicationInstruction';
import MedicationInstructions from 'screens/MedicationInstructions/MedicationInstructions';
import { Messages } from 'screens/Messages/Messages';
import MobileOAuth from 'screens/MobileOAuth';
import MyJourney from 'screens/MyJourney/MyJourney';
import { MyProfile } from 'screens/MyProfile/MyProfile';
// Layouts
import { Resources } from 'screens/Resources/Resources';
import { Results } from 'screens/Results/Results';
import { Results as ResultsV2 } from 'screens/ResultsV2/Results';
import { pageUrl, RoutesPath } from 'utilities/pageUrl';

import { redirectChicagoPatients } from './RedirectChicagoPatients';
import RedirectToEnterpriseMembership from './RedirectToEnterpriseMembership';
import { RedirectWithSearchParams } from './RedirectWithSearchParams';
import { withProtectedRoute } from './withProtectedRoute';

export const Redirects = [
  {
    from: RoutesPath.SIGN_UP,
    to: RoutesPath.CREATE_ACCOUNT
  },
  {
    from: RoutesPath.CARE_TEAM,
    to: pageUrl.myProfile('care-team')
  }
].map(({ from, to }) => <RedirectWithSearchParams key={from} from={from} to={to} />);

export const Routes = [
  {
    path: RoutesPath.DASHBOARD,
    component: withProtectedRoute(Dashboard),
    layout: MainColoredLayout,
    page: {
      name: 'Dashboard'
    }
  },
  {
    path: RoutesPath.CREATE_ACCOUNT,
    component: CreateAccountScreen,
    layout: AuthLayout
  },
  {
    path: RoutesPath.APPOINTMENTS,
    component: withProtectedRoute(Appointments),
    layout: MainLayout
  },
  {
    path: RoutesPath.APPOINTMENTS_V2,
    component: withProtectedRoute(AppointmentsV2),
    layout: MainColoredLayout
  },
  {
    path: RoutesPath.BOOK,
    component: redirectChicagoPatients(withProtectedRoute(Book)),
    layout: MainLayout,
    hiddenFromVios: true
  },
  {
    path: RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE,
    component: withProtectedRoute(ActivateMembershipScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.VERIFY_MEMBERSHIP_ELIGIBILITY,
    component: withProtectedRoute(VerifyEligibilityScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.ENTER_MEMBERSHIP_UNIQUE_ID,
    component: withProtectedRoute(UniqueIdScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.ACTIVATE_MEMBERSHIP_WALMART_WIN_BID,
    component: withProtectedRoute(WalmartWinOrBidScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.ACTIVATE_MEMBERSHIP_WALMART_CONFIRM_IDENTITY,
    component: withProtectedRoute(WalmartConfirmYourIdentityScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.CONFIRM_MEMBERSHIP_ACTIVATION,
    component: withProtectedRoute(ConfirmationMembershipScreen),
    layout: MainLayout
  },
  {
    path: RoutesPath.RESULTS,
    component: withProtectedRoute(Results),
    layout: MainLayout
  },
  {
    path: RoutesPath.RESULTS_V2,
    component: withProtectedRoute(ResultsV2),
    layout: MainColoredLayout
  },
  // TODO Remove when all links (emails, outside of this app, etc.) have been migrated
  {
    path: RoutesPath.MY_JOURNEY,
    component: withProtectedRoute(MyJourney),
    layout: MainLayout,
    hiddenFromVios: true
  },
  {
    path: RoutesPath.DOCUMENTS,
    component: withProtectedRoute(Documents),
    layout: MainColoredLayout,
    hiddenFromVios: true
  },
  {
    path: RoutesPath.LOGIN,
    component: LoginScreen,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.LOGIN_SSO_EPIC,
    component: LoginSsoEpicDisney,
    layout: SSOLoginLayout
  },
  {
    path: RoutesPath.LOGIN_SSO_MEDTRONIC,
    component: LoginSsoAzureMedtronic,
    layout: SSOLoginLayout
  },
  {
    path: RoutesPath.LOGOUT,
    component: Logout,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.ACCESS_ACCOUNT,
    component: AccessAccount,
    layout: SimpleLayout
  },
  {
    path: `${RoutesPath.CONSENTS}/:id`,
    component: withProtectedRoute(InformedConsent),
    layout: MainLayout
  },
  {
    path: RoutesPath.VERIFICATION_CODE,
    component: EmailVerificationScreen,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.MEDICATION_INSTRUCTIONS,
    component: MedicationInstructions,
    layout: Plain
  },
  {
    path: RoutesPath.MEDICATION_INSTRUCTION,
    component: MedicationInstruction,
    layout: Plain
  },
  {
    path: RoutesPath.MOBILE_OAUTH,
    component: MobileOAuth,
    layout: Plain
  },
  {
    path: RoutesPath.MY_PROFILE,
    component: withProtectedRoute(MyProfile),
    layout: MainLayout
  },
  {
    path: RoutesPath.BOOKING_QUIZ,
    component: redirectChicagoPatients(BookingQuiz),
    layout: SimpleLayout
  },
  {
    path: RoutesPath.ACTIVATE_KINDBODY_BENEFIT,
    component: RedirectToEnterpriseMembership,
    layout: MainLayout
  },
  {
    path: RoutesPath.KINDBODY_BENEFIT,
    component: RedirectToEnterpriseMembership,
    layout: MainLayout
  },
  {
    path: RoutesPath.INVOICES,
    component: withProtectedRoute(Invoices),
    layout: MainColoredLayout
  },
  {
    path: `${RoutesPath.INVOICES}/:id`,
    component: withProtectedRoute(InvoiceDetails),
    layout: MainColoredLayout
  },
  {
    path: RoutesPath.RESOURCES,
    component: withProtectedRoute(Resources),
    layout: MainColoredLayout,
    hiddenFromVios: true
  },
  {
    path: RoutesPath.KINDBODY_360,
    component: withProtectedRoute(Kindbody360Index),
    layout: MainLayout
  },
  {
    path: `${RoutesPath.CYCLE_PLAN}/:id/acknowledge`,
    component: withProtectedRoute(CyclePlanAcknowledge),
    layout: MainLayout
  },
  {
    path: RoutesPath.PATIENT_INTAKE,
    component: withProtectedRoute(IntakeForm),
    layout: Plain
  },
  {
    path: [RoutesPath.VIOS_PAYMENT, RoutesPath.VIOS_PAYMENTS],
    component: DirectPayment,
    layout: SimpleLayout
  },
  {
    path: `${RoutesPath.VIRTUAL_MEETING}/:appointmentPublicIdentifier`,
    component: AppointmentVirtualMeeting,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.CHANGE_EMAIL,
    component: withProtectedRoute(EmailChange),
    layout: SimpleLayout,
    hiddenFromVios: true
  },
  {
    path: RoutesPath.CONFIRM_NEW_EMAIL,
    component: ConfirmNewEmail,
    layout: SimpleLayout
  },
  {
    path: RoutesPath.MESSAGES,
    component: withProtectedRoute(Messages),
    layout: MainMessageLayout,
    hiddenFromVios: true
  },
  {
    path: `${RoutesPath.MESSAGES}/:categoryName/:conversationId`,
    component: withProtectedRoute(Messages),
    layout: MainMessageLayout,
    hiddenFromVios: true
  },
  {
    path: `${RoutesPath.MESSAGES}/:categoryName`,
    component: withProtectedRoute(Messages),
    layout: MainMessageLayout,
    hiddenFromVios: true
  }
];
