import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Heading } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';
import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { calculateNextCycleVisitDate } from 'screens/DashboardV2/CycleVisit/CycleVisit.mapper';
import { CYCLE_VISIT_DAYS } from 'screens/DashboardV2/Dashboard.graphql';
import { CycleVisitDaysResponse } from 'screens/DashboardV2/Dashboard.types';
import { ResponsiveContainer } from 'screens/styled_common';
import { pageUrl } from 'utilities/pageUrl';

import { BookingButton, Content, HeaderContainer } from './Appointments.styled';
import { NoAppointment } from './components/NoAppointment/NoAppointment';
import { PastAppointments } from './components/PastAppointments/PastAppointments';
import { UpcomingAppointments } from './components/UpcomingAppointments/UpcomingAppointments';

export const Appointments: React.FC = () => {
  const history = useHistory();

  const { data, loading } = useQuery<{ appointments: Appointment[] }>(PATIENT_APPOINTMENTS, {
    onError: error => BugTracker.notify(error, 'PatientAppointments-Appointments.tsx')
  });
  const { data: cycleVisitData, loading: cycleVisitLoading } = useQuery<CycleVisitDaysResponse>(
    CYCLE_VISIT_DAYS,
    {
      onError: error => BugTracker.notify(error, 'Cycle Visit Days Fetch')
    }
  );
  const nextCycleVisit = calculateNextCycleVisitDate(
    cycleVisitData?.cycleVisitDays,
    cycleVisitData?.cycleVisits
  );
  const hasAppointment = !!data?.appointments.length || nextCycleVisit;

  if (loading || cycleVisitLoading) return <Loader height={150} width={150} absoluteCentered />;

  return (
    <ResponsiveContainer>
      <HeaderContainer>
        <Heading tag="h1" noMargin>
          My Appointments
        </Heading>
        {hasAppointment && (
          <BookingButton
            category="primary"
            label="Schedule Appointment"
            onClick={() => history.push(pageUrl.booking.bookingQuiz())}
            size="md"
          />
        )}
      </HeaderContainer>
      <Content>
        {hasAppointment ? (
          <>
            <UpcomingAppointments
              appointments={data?.appointments}
              nextCycleVisit={nextCycleVisit}
              cycleVisitData={cycleVisitData}
            />
            <PastAppointments appointments={data?.appointments} />
          </>
        ) : (
          <NoAppointment />
        )}
      </Content>
    </ResponsiveContainer>
  );
};
